<template>
  <div class="loginOperatePage">
    <Breadcrumb :title="$route.meta.title" />
    <section class="filterWrap">
      <!-- 時間 -->
      <div class="itemWrap">
        <label>查詢日期：</label>
        <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
          start-placeholder="開始日期" 
          end-placeholder="結束日期"
          v-model="rangeDate"
        ></el-date-picker>
      </div>
      <!-- 名單類型 -->
      <div class="itemWrap">
        <label>查詢單位：</label>
        <el-select v-model="listQuery.DeptName" multiple clearable placeholder="請選擇單位" no-data-text="目前無名單">
          <el-option v-for="item in deptNameSelectData"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <el-button @click="doSearch" type="primary" class="searchLog">查詢</el-button>
    </section>
    <div class="loginRecordPage__Content">
      <div class="loginRecordPage__listBox">
        <div class="loginRecordPage__listBox--header">
          <h2>系統操作記錄表</h2>
          <div class="exportWrap">
            <download-csv :data="CSVData" name="系統操作記錄表.csv">
              <el-button type="primary">匯出</el-button>
            </download-csv>
          </div>
        </div>
        <div class="loginRecordPage__listBox--content">
          <el-table ref="multipleTable" border  style="width: 100%"  empty-text="暫無數據" max-height="500"
            :data="tableData"
          >
            <el-table-column label="單位"  prop="DeptName" width="100">
              <template slot-scope="scope">
                <div>{{ scope.row.DeptName?scope.row.DeptName:"無" }}</div>
              </template>
            </el-table-column>

            <el-table-column label="使用者" prop="UserName"  width="100">
              <template slot-scope="scope">
                <div>{{ scope.row.UserName?scope.row.UserName:"無" }}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作時間" prop="CreateDt" width="180">
              <template slot-scope="scope">
                <div>{{ filterDate(scope.row.CreateDt) }}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作功能" prop="EndPoint" width="150"></el-table-column>
            <el-table-column label="操作內容" prop="UseCont"></el-table-column>
          </el-table>
        </div>
        <Pagination
          @currentChange="pageChange"
          :pageSize="this.listQuery.Page_Size"
          :propsCurrentPage="this.listQuery.Page_Num"
          :totalLists="totalLists"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  components: { Breadcrumb ,Pagination},
  data() {
    return {
      rangeDate: [],
      tableData: [],
      CSVData: [],
      totalLists: null,
      deptNameSelectData: [],
      listQuery: {
        ComName: this.$store.state.userInfo.ComName,
        End_Date: "",
        Start_Date: "",
        DeptName: "",
        Page_Size: 10,
        Page_Num: 1,
      },
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
  },
  methods: {
    doSearch(){
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1
      this.$store.dispatch("loadingHandler", true);
      this.deleteNullValue();
      let temp = JSON.parse(JSON.stringify(this.listQuery))
      temp.IsUser = true;
      this.$api.record.actionRecord(temp).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
      this.getAllExportData();
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      this.deleteNullValue();
      this.$api.record.actionRecord(this.listQuery).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
    },
    getSelectOption() {
      this.$api.system
        .getCompanyInfo({ComName: this.$store.state.userInfo.ComName})
        .then((res) => {
          this.deptNameSelectData = res.department;
        });
    },
    getAllExportData(){
      let requestData = {};
      requestData = JSON.parse(JSON.stringify(this.listQuery));
      requestData.Page_Num = 1;
      requestData.Page_Size = 9999;
      this.$api.record.actionRecord(requestData).then((res) => {
        let CSVData = [];
        res.data.forEach((item) => {
          const { DeptName, UserName, CreateDt, EndPoint, UseCont } = item;
          CSVData.push({ DeptName, UserName, CreateDt, EndPoint, UseCont });
        });
        this.CSVData = CSVData;
      });
      
    }
  },
  mounted() {
    this.getList();
    this.getSelectOption();
    this.getAllExportData()
  },
};
</script>

<style lang="scss">
.loginOperatePage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  &__Content{
    padding: 0px 1% 1% 1%;
  }
  &__listBox {
    &--header {
      text-align: center;
      .exportWrap{
        width: 70px;
        text-align: right;
        margin-left: auto;
     }
    }
  }
  .filterWrap{
    padding: 1%;
    .itemWrap{
      margin-bottom: 10px;
      width: 500px;
      display: flex;
      align-items: center;
      label{
        width:16%;
      }
      &:nth-child(2),&:nth-child(3){
        .el-select{
          width: 50%;
        }
      }
      &:nth-child(4){
        .el-input{
          width: 50%;
        }
      }
    }
    
  }
}
</style>
